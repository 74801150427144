import React, { useEffect, useState } from "react";
import Layout from "../_components/Layout/Layout";
import Reward from "../_components/Reward/Reward";
import MainTitle from "../_components/MainTitle/MainTitle";
import PageSummary from "../_components/PageSummary/PageSummary";
import Database from "../_utils/Database";

const QrRewards = () => {
    const [rewardsMessage, setRewardsMessage] = useState();
    const [rewardsList, setRewardsList] = useState();

    useEffect(() => {
        setRewardsMessage(Database.getRewardsMessage());
        setRewardsList(Database.getRewardsList());
    }, []);

    return (
        <Layout title="Rewards">
            <MainTitle>
                YOUR GIFT
            </MainTitle>

            {rewardsMessage && <PageSummary>{rewardsMessage}</PageSummary>}

            {rewardsList && rewardsList.map((reward, key) => (
                <Reward
                    key={`reward-${key}`}
                    image={reward.reward_image_url}
                    title={reward.reward_title}
                    description={reward.reward_message}
                />
            ))}
        </Layout>
    );
};

export default QrRewards;
