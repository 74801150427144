import * as React from "react";
import "./styles.scss";

const Reward = ({
    image,
    title,
    description
}) => {
    return (
        <div className="reward">
            <img
                src={image}
                alt={title}
            />

            <div className="info-box">
                <h3 className="title">
                    {title}
                </h3>

                <p className="text">
                    {description}
                </p>
            </div>
        </div>
    );
};

export default Reward;
